[data-component='App'] {
  height: 100%;
  width: 100%;
  position: relative;

  &.light-mode {
    background-color: #ffffff;
    color: #000000;
  }

  &.dark-mode {
    background-color: #181818;
    color: #f0f0f0;
  }
}
